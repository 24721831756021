class Gallery {
  constructor() {
    const isX = !(window.UiO2 && window.UiO2.url);

    this.config = window.galleryConfig || {
      width: {
        desktop: {
          max: (isX ? 700 : 800),
          minWithoutCaption: 300
        },
        mobile: {
          fullwidthMinusMargin: 110
        },
        fullScreen: {
          maxOfTotalWidth: 0.77
        }
      },
      height: {
        desktop: {
          maxWithoutCaption: 425
        },
        mobile: {
          max: 0 // initial value, find max height in gallery images (with constraint rotated mobile width)
        },
        fullScreen: {
          maxOfTotalHeight: 0.77
        }
      },
      mobileBreakpoint: (isX ? 750 : 768),
      leftGap: window.galleryConfigGap ? window.galleryConfigGap : 150,
      transformation: {
        gap: 18,
        scaleNotActive: 0.8
      },
      shouldRunGridFix: isX
    };
  }
  init(galleryId) {
    setTimeout(() => {
      this.main(galleryId);
    }, 25);
  }

  main(galleryId) {
    this.gallery = document.getElementById("vrtx-image-gallery-include-" + galleryId);
    this.galleryParent = this.gallery.parentElement;
    this.htmlTag = document.getElementsByTagName("html")[0];

    const lang = this.htmlTag.getAttribute("lang");
    if(lang === "en") {
      this.i18n = {
        openThumbnailView: "Show overview of images",
        closeThumbnailView: "Show image carousel"
      };
    } else {
      this.i18n = {
        openThumbnailView: "Vis bildeoversikt",
        closeThumbnailView: "Vis bildekarusell"
      };
    }

    this.images = [];
    this.thumbsNeedsResize = false;

    this.galleryParent.classList.add("vrtx-image-gallery-include-outer-wrapper");

    this.turnOffAnimation();

    this.gallery.querySelectorAll("figure").forEach((item, i) => {
      var src = item.querySelector("img").getAttribute("src");
      var width = item.querySelector("img").getAttribute("width");
      var height = item.querySelector("img").getAttribute("height");
      var desc = item.querySelectorAll("figcaption p").length > 1 ? "yes" : "";
      this.images.push({
        "width": width,
        "height": height,
        "desc": desc,
        "src": src
      })
    });

    this.setupClickListeners();

    //moveFromUrlParam();

    this.transformation();
    this.gridFix();
    this.refreshGallerySize();

    this.loadImages();
    this.addAllFullImagesAfterLoad();

    this.turnOnAnimation();
  }

  setupClickListeners() {
    window.addEventListener("resize", () => {
      this.gridFix();

      this.refreshGallerySize();
      this.thumbsNeedsResize = true;
      if(this.gallery.parentElement.classList.contains("vrtx-image-gallery-thumbnail-view")) {
        const scrollWrapper = this.gallery.getElementsByClassName("vrtx-image-gallery-include-thumbs-scroll-wrapper")[0];
        this.makeThumbsOverviewScrollable(scrollWrapper);
      }
    });

    // Dynamic event handler on gallery
    this.galleryParent.addEventListener("click", (e) => {
      let isFullScreenLink = e.target && e.target.classList.contains("vrtx-image-gallery-show-fullscreen");
      let isFullScreenCloseLink = e.target && e.target.classList.contains("vrtx-image-gallery-close-fullscreen");
      let isActiveImage = e.target && e.target.nodeName === "IMG"
                                   && e.target.parentElement.classList.contains("vrtx-image-gallery-image-container")
                                   && e.target.parentElement.parentElement.classList.contains("active");
      if(isFullScreenLink || isFullScreenCloseLink || (isActiveImage && window.innerWidth > this.config.mobileBreakpoint)) { // Not mobile
        this.toggleFullScreen(e.target);
        e.stopPropagation();
        e.preventDefault();
      }

      let isThumbnailViewLink = e.target && e.target.classList.contains("vrtx-image-gallery-toggle-thumbnail-view");
      if(isThumbnailViewLink) {
        this.toggleThumbnailView(e.target);
        e.stopPropagation();
        e.preventDefault();
      }
      let isThumbnailLink = e.target.parentElement.parentElement.classList.contains("vrtx-image-gallery-include-thumbs-pure-css")
                         || e.target.parentElement.parentElement.parentElement.classList.contains("vrtx-image-gallery-include-thumbs-pure-css");
      if(isThumbnailLink) {
        let thumbnail = e.target;
        if(e.target.nodeName === "A") {
          thumbnail = e.target.getElementsByTagName("IMG")[0];
        }
        this.moveFromThumbnail(thumbnail);
        e.stopPropagation();
        e.preventDefault();
      }
    });

    document.addEventListener("keydown", (e) => {
      if(e.key === "ArrowLeft") {
        if(this.galleryParent.classList.contains("vrtx-image-gallery-selected-fullscreen")) {
          this.movePrevNext("prev");
          e.stopPropagation();
          e.preventDefault();
        }
      }
    });

    document.addEventListener("keydown", (e) => {
      if(e.key === "ArrowRight") {
        if(this.galleryParent.classList.contains("vrtx-image-gallery-selected-fullscreen")) {
          this.movePrevNext("next");
          e.stopPropagation();
          e.preventDefault();
        }
      }
    });

    document.addEventListener("keydown", (e) => {
      if(e.key === "Escape") {
        if(this.galleryParent.classList.contains("vrtx-image-gallery-selected-fullscreen")) {
          this.toggleFullScreen();
        }
      }
    });

    this.gallery.getElementsByClassName("next")[0].addEventListener("click", (e) => {
      this.movePrevNext("next");
      e.stopPropagation();
      e.preventDefault();
    });

    this.gallery.getElementsByClassName("prev")[0].addEventListener("click", (e) => {
      this.movePrevNext("prev");
      e.stopPropagation();
      e.preventDefault();
    });
    this.gallery.getElementsByClassName("thumbs-prev")[0].addEventListener("click", (e) => {
      this.movePrevNextThumbs("prev");
      e.stopPropagation();
      e.preventDefault();
    });

    this.gallery.getElementsByClassName("thumbs-next")[0].addEventListener("click", (e) => {
      this.movePrevNextThumbs("next");
      e.stopPropagation();
      e.preventDefault();
    });
  }

  gridFix() {
    if(!this.config.shouldRunGridFix) return;

    // If below where 1000px intro,h1 starts scaling down
    if(window.innerWidth < (1265 - this.config.leftGap) && window.innerWidth > 1100) {
      // works for now, all articles should have h1
      // (it was based on 100%, but since article body in X is set to 700px it has be
      // width in px that caused scaling to not work)
      const h1 = document.querySelector("h1");
      if(h1) {
        this.gallery.parentElement.style.width = h1.offsetWidth + this.config.leftGap + "px";
      }
    } else {
      this.gallery.parentElement.removeAttribute("style");
    }
  }

  movePrevNext(direction) { // Endless scroll in both directions
    const active = this.gallery.querySelector(".vrtx-image-gallery-image.active");
    let newActive = null;
    let endCondition = null;
    if(direction === "next") {
      newActive = active.nextElementSibling;
      endCondition = newActive === null || newActive.classList.contains("last-img-gallery-fix");
      if(endCondition) {
        newActive = active.parentElement.querySelector(":scope > .vrtx-image-gallery-image");
      }
    } else {
      newActive = active.previousElementSibling;
      endCondition = newActive.classList.contains("vrtx-image-gallery-include-container-nav");
      if(endCondition) {
        const elms = active.parentElement.querySelectorAll(":scope > .vrtx-image-gallery-image");
        newActive = elms[elms.length- 1];
      }
    }
    newActive.classList.add("active");
    active.classList.remove("active");
    this.transformation();
    this.updateProgressNr(newActive);
  }

  movePrevNextThumbs(direction) { // Finite scroll
    const active = this.gallery.getElementsByClassName("active-thumbs-in-scroll")[0];
    let newActive = null;
    let endCondition = null;
    if(direction === "next") {
      newActive = active.nextElementSibling;
      endCondition = newActive === null;
    } else {
      newActive = active.previousElementSibling;
      endCondition = newActive.classList.contains("thumbs-prev-next-wrapper");
    }
    if(!endCondition) {
      newActive.classList.add("active-thumbs-in-scroll");
      active.classList.remove("active-thumbs-in-scroll");
      this.thumbsTransformation();
    }
  }

  getUrlParameter(url, parameterName) {
    parameterName = parameterName.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var regex = new RegExp("[\\?&]" + parameterName + "=([^&#]*)");
    var results = regex.exec(url);
    return (results === null) ? null : results[1];
  }

  moveFromUrlParam() {
    const activeImgFromUrl = getUrlParameter(location.href, "actimg");
    if(activeImgFromUrl === null) return;

    let thumbnailImgSrc = activeImgFromUrl.replace(/^https\:/, "");
    let bigImg = this.gallery.querySelector(".vrtx-image-gallery-include-container img[src^='" + thumbnailImgSrc + "']");

    const active = this.gallery.querySelector(".vrtx-image-gallery-image.active");
    const newActive = bigImg.parentElement.parentElement;

    // If first image that is active is default, do nothing
    if(active === newActive) return;

    newActive.classList.add("active");
    active.classList.remove("active");

    this.updateProgressNr(newActive);
  }

  moveFromThumbnail(image) {
    let thumbnailImgSrc = image.getAttribute("src").replace(/\?.*/, "");
    let bigImg = this.gallery.querySelector(".vrtx-image-gallery-include-container img[src^='" + thumbnailImgSrc + "']");

    const active = this.gallery.querySelector(".vrtx-image-gallery-image.active");
    const newActive = bigImg.parentElement.parentElement;

    if(active !== newActive) {
      newActive.classList.add("active");
      active.classList.remove("active");

      this.updateProgressNr(newActive);
    }

    const link = this.galleryParent.querySelector(".vrtx-image-gallery-toggle-thumbnail-view");
    this.toggleThumbnailView(link);

    // Need to retrap if fullscreen
    let htmlTagClasses = this.htmlTag.classList;
    let galleryParentClasses = this.galleryParent.classList;
    if(htmlTagClasses.contains("vrtx-image-gallery-fullscreen")) {
      this.galleryParent.getElementsByClassName("next")[0].focus();
      this.trapFullscreenFocus(this.galleryParent);
    }
  }

  toggleFullScreen(link) {
    let htmlTagClasses = this.htmlTag.classList;
    let galleryParentClasses = this.galleryParent.classList;
    if(!htmlTagClasses.contains("vrtx-image-gallery-fullscreen")) {
      htmlTagClasses.add("vrtx-image-gallery-fullscreen");
      galleryParentClasses.add("vrtx-image-gallery-selected-fullscreen");
      this.galleryParent.getElementsByClassName("vrtx-image-gallery-close-fullscreen")[0].focus();
      this.trapFullscreenFocus(this.galleryParent);
    } else {
      htmlTagClasses.remove("vrtx-image-gallery-fullscreen");
      galleryParentClasses.remove("vrtx-image-gallery-selected-fullscreen");
      this.gridFix();
    }
    this.refreshGallerySize();
  }

  toggleThumbnailView(link) {
    let galleryParentClasses = this.galleryParent.classList;
    if(!galleryParentClasses.contains("vrtx-image-gallery-thumbnail-view")) {
      galleryParentClasses.add("vrtx-image-gallery-thumbnail-view");
      link.textContent = this.i18n.closeThumbnailView;
      link.classList.add("thumbnail-view-close");

      const scrollWrapper = this.gallery.getElementsByClassName("vrtx-image-gallery-include-thumbs-scroll-wrapper")[0];
      this.makeThumbsOverviewScrollable(scrollWrapper);
    } else {
      galleryParentClasses.remove("vrtx-image-gallery-thumbnail-view");
      link.textContent = this.i18n.openThumbnailView;
      link.classList.remove("thumbnail-view-close");
      this.refreshGallerySize();
    }
  }

  transformation() {
    let active = this.gallery.querySelector(".vrtx-image-gallery-image.active");
    const activeDesc = active.getElementsByClassName("vrtx-image-gallery-image-description")[0];

    const isFullScreen = this.gallery.parentElement.classList.contains("vrtx-image-gallery-selected-fullscreen");
    let maxHeight = this.config.height.desktop.maxWithoutCaption;
    if(isFullScreen) {
      maxHeight = document.documentElement.clientHeight * this.config.height.fullScreen.maxOfTotalHeight;
    } else {
      if(window.innerWidth <= this.config.mobileBreakpoint) {
        maxHeight = Math.min(this.config.height.mobile.max, document.documentElement.clientWidth - this.config.width.mobile.fullwidthMinusMargin);
      }
    }

    if(window.innerWidth <= this.config.mobileBreakpoint) {
      this.xtraMobileTransformation(maxHeight + "px");
    } else {
      this.xtraMobileTransformation("");
    }
    this.gallery.getElementsByClassName("vrtx-image-gallery-include-container")[0].style.height = maxHeight + this.getOuterHeight(activeDesc) + (isFullScreen ? 90 : 0) + "px";

    // Calcuate active image position
    const offsetActiveLeft = (this.gallery.clientWidth - active.clientWidth) / 2;
    active.style.transform = "translate3d(" + offsetActiveLeft + "px," + this.calcTransformY(true, active, isFullScreen, maxHeight, this.getOuterHeight(activeDesc)) + ",0) scale(1)";

    var all = this.gallery.getElementsByClassName("vrtx-image-gallery-image");
    const alls = [...all];

     // Detect changes from prev to next further on
    alls.filter(item => item.classList.contains("active-before")).forEach((item) => {
      item.classList.add("active-before-tmp");
    });
    // Detect changes from next to prev further on
    alls.filter(item => item.classList.contains("active-after")).forEach((item) => {
      item.classList.add("active-after-tmp");
    });
    let parent = all[0].parentElement;
    alls.forEach((elm) => {
      elm.classList.remove("active-before", "active-after", "active-prev", "active-prev-prev", "active-next", "active-next-next");
    });

    const half = Math.floor((all.length - 1) / 2); // Minus active
    const activeIdx = [...active.parentNode.children].indexOf(active);
    const prevAround = activeIdx - (half + 1);
    if(prevAround < 0) {
      const slice1Cut = all.length - (prevAround * -1);
      const slice2Cut = activeIdx - (half - (prevAround * -1));
      alls.slice(slice1Cut, all.length).forEach((elm) => {
        elm.classList.add("active-before");
      });
      if(slice2Cut < activeIdx) {
        alls.slice(slice2Cut-1, activeIdx-1).forEach((elm) => {
          elm.classList.add("active-before");
        });
      }
    } else {
      const slice2Cut = activeIdx - half;
      alls.slice(slice2Cut-1, activeIdx-1).forEach((elm) => {
        elm.classList.add("active-before");
      });
    }

    alls.filter(elm => !elm.classList.contains("active-before")
                    && !elm.classList.contains("active")).forEach((elm) => {
      elm.classList.add("active-after");
    });

    // Now that have marked all before and after active..

    let offsetPrevLeft = offsetActiveLeft;
    let scaleAccumulator = 0;
    const prevTransform = (image) => {
      scaleAccumulator += this.calcPositionLeftWithScale(image);
      offsetPrevLeft -= image.clientWidth - scaleAccumulator + this.config.transformation.gap;
      if(image.classList.contains("active-after-tmp")) { // From next to prev
        setTimeout(() => {
          image.classList.add("loop-around");
        }, 50);
      }
      let imageDesc = image.getElementsByClassName("vrtx-image-gallery-image-description")[0];
      image.style.transform = "translate3d(" + offsetPrevLeft + "px," + this.calcTransformY(false, image, isFullScreen, maxHeight, this.getOuterHeight(imageDesc)) + ",0) scale(" + this.config.transformation.scaleNotActive + ")";
    };
    this.prevUntil(active, ":not(.active-before)").forEach(prevTransform);
    this.prevUntil(parent.querySelector(":scope > *:last-child"), ":not(.active-before)", true).forEach(prevTransform);

    let offsetNextLeft = offsetActiveLeft;
    scaleAccumulator = 0;
    let init = false;
    let curImg = null;
    const nextTransform = (image) => {
      scaleAccumulator += this.calcPositionLeftWithScale(image);
      if(!init) {
        offsetNextLeft += active.clientWidth + this.config.transformation.gap - scaleAccumulator;
        init = true;
      } else {
        offsetNextLeft += curImg.clientWidth + this.config.transformation.gap - scaleAccumulator;
      }
      if(image.classList.contains("active-before-tmp")) { // From prev to next
        setTimeout(() => {
          image.classList.add("loop-around");
        }, 50);
      }
      let imageDesc = image.getElementsByClassName("vrtx-image-gallery-image-description")[0];
      image.style.transform = "translate3d(" + offsetNextLeft + "px," + this.calcTransformY(false, image, isFullScreen, maxHeight, this.getOuterHeight(imageDesc)) + ",0) scale(" + this.config.transformation.scaleNotActive + ")";
      curImg = image;
    };
    this.nextUntil(active, ":not(.active-after)").forEach(nextTransform);
    this.nextUntil(parent.querySelector(":scope > *:first-child"), ":not(.active-after)").forEach(nextTransform);

    const prev = active.matches(":first-of-type") ? parent.querySelector(":scope > figure:last-of-type") : active.previousElementSibling;
    const prevPrev = prev.matches(":first-of-type") ? parent.querySelector(":scope > figure:last-of-type") : prev.previousElementSibling;
    const next = active.matches(":last-of-type") ? parent.querySelector(":scope > figure:first-of-type") : active.nextElementSibling;
    const nextNext = next.matches(":last-of-type") ? parent.querySelector(":scope > figure:first-of-type") : next.nextElementSibling;
    prev.classList.add("active-prev");
    prevPrev.classList.add("active-prev-prev");
    next.classList.add("active-next");
    nextNext.classList.add("active-next-next");

    alls.forEach((elm) => {
      elm.classList.remove("active-before-tmp", "active-after-tmp");
    });

    setTimeout(function() { // Remove after most of animation
      alls.forEach((elm) => {
        elm.classList.remove("loop-around");
      });
    }, 350);
  }

  xtraMobileTransformation(maxHeight) {
    this.gallery.getElementsByClassName("vrtx-image-gallery-include-container-nav")[0].style.height = maxHeight;
    var allContainers = this.gallery.getElementsByClassName("vrtx-image-gallery-image-container");
    const allsContainers = [...allContainers];
    allsContainers.forEach((elm) => {
      elm.style.height = maxHeight;
    });
  }

  thumbsShowHidePrevNext(scrollWrapper, active) {
    if(!active.nextElementSibling) {
      this.gallery.getElementsByClassName("thumbs-next")[0].classList.add("hidden-thumbs-direction");
    } else {
      this.gallery.getElementsByClassName("thumbs-next")[0].classList.remove("hidden-thumbs-direction");
    }
    if(active.previousElementSibling.classList.contains("thumbs-prev-next-wrapper")) {
      this.gallery.getElementsByClassName("thumbs-prev")[0].classList.add("hidden-thumbs-direction");
    } else {
      this.gallery.getElementsByClassName("thumbs-prev")[0].classList.remove("hidden-thumbs-direction");
    }
    scrollWrapper.querySelectorAll(".vrtx-image-gallery-include-thumbs a").forEach((link) => {
      link.setAttribute("tabindex", "-1");
    });
    active.querySelectorAll("a").forEach((link) => {
      link.setAttribute("tabindex", "0");
    });
  }

  thumbsTransformation() {
    const scrollWrapper = this.gallery.getElementsByClassName("vrtx-image-gallery-include-thumbs-scroll-wrapper")[0];
    if(!scrollWrapper.classList.contains("activate")) return;

    const active = this.gallery.querySelector(".vrtx-image-gallery-include-thumbs.active-thumbs-in-scroll");

    this.thumbsShowHidePrevNext(scrollWrapper, active);

    active.style.transform = "translate3d(0,0,0)";

    // Now that have marked all before and after active..
    let offsetX = 0;
    const prevTransform = function(li) {
      offsetX -= scrollWrapper.clientWidth;
      li.style.transform = "translate3d(" + offsetX + "px,0,0)";
    };
    this.prevUntil(active, ".thumbs-prev-next-wrapper").forEach(prevTransform);

    offsetX = 0;
    const nextTransform = function(li) {
      offsetX += scrollWrapper.clientWidth;
      li.style.transform = "translate3d(" + offsetX + "px,0,0)";
    };
    this.nextUntil(active).forEach(nextTransform);
  }

  makeThumbsOverviewScrollable(scrollWrapper) {
    if(!this.thumbsNeedsResize && scrollWrapper.classList.contains("activate")) return;
    this.thumbsNeedsResize = false;

    let thumbs = this.gallery.querySelectorAll(".vrtx-image-gallery-include-thumbs");
    if(thumbs.length > 1) { // Flatten so can calculate scrollable sections again (dynamic depends on content width)
      scrollWrapper.classList.remove("activate");
      scrollWrapper.removeAttribute("style");
      this.gallery.querySelectorAll(".vrtx-image-gallery-include-thumbs").forEach((elm, i) => {
        if(i > 0) {
          thumbs[0].innerHTML = thumbs[0].innerHTML + elm.innerHTML;
          elm.remove();
        }
      });
      thumbs = this.gallery.querySelector(".vrtx-image-gallery-include-thumbs");
      thumbs.removeAttribute("style");
      thumbs.classList.remove("active-thumbs-in-scroll");
    } else {
      thumbs = thumbs[0];
    }

    const splitAfterRow = 3;
    const len = thumbs.querySelectorAll("li").length;

    let prevTop = -1;
    let height = 0;
    let lineCount = 0;
    let scrollArray = [];
    let removeArray = [];
    let numScrollSections = 0;
    let newHtml = "";

    thumbs.querySelectorAll("li").forEach((li, i) => {
      let offsetTop = li.offsetTop;
      if(offsetTop > prevTop) {
        if(lineCount === splitAfterRow) {
          height = offsetTop;
        }
        lineCount++;
        prevTop = offsetTop;
        if(scrollArray.length > 0 && ((lineCount-1) % splitAfterRow === 0)) { // Next scrollable row
          numScrollSections++;
          newHtml += this.makeThumbsNewRow(scrollWrapper, scrollArray, height, numScrollSections);
          scrollArray = [];
        }
      }
      if(lineCount > splitAfterRow) { // Split for scrollable
        scrollArray.push(li.outerHTML);
        removeArray.push(li);
      }
      if((i === (len - 1))) {
        if(scrollArray.length > 0) { // If next scrollable row ends early
          numScrollSections++;
          newHtml += this.makeThumbsNewRow(scrollWrapper, scrollArray, height, numScrollSections);
          scrollArray = [];
        }

        if(numScrollSections > 0) {
          scrollWrapper.insertAdjacentHTML("beforeend", newHtml);
          removeArray.forEach((elm) => {
            elm.remove();
          });
          const active = this.gallery.querySelector(".vrtx-image-gallery-include-thumbs");
          active.classList.add("active-thumbs-in-scroll");
          scrollWrapper.classList.add("activate");
          this.thumbsShowHidePrevNext(scrollWrapper, active);
        } else {
          thumbs.querySelectorAll("a").forEach((link) => {
            link.setAttribute("tabindex", "0");
          });
        }
      }
    });
  }

  makeThumbsNewRow(scrollWrapper, scrollArray, prevTop, numScrollSections) {
    const thumbs = this.gallery.getElementsByClassName("vrtx-image-gallery-include-thumbs")[0];

    if(numScrollSections === 1) {
      scrollWrapper.style.height = (prevTop - 5) + "px";
    }
    const posX = thumbs.clientWidth * numScrollSections + "px";

    return `
      <ul class="vrtx-image-gallery-include-thumbs-pure-css vrtx-image-gallery-include-thumbs"
          style="transform: translate3d(${posX},0,0)">
        ${scrollArray.join("")}
      </ul>`;
  }

  nextUntil(elem, selector, includeCurrent) {
  	var siblings = [];
    if((typeof includeCurrent !== "boolean" || includeCurrent === false) && typeof elem != "undefined") {
      elem = elem.nextElementSibling;
    }
  	while (elem) {
  		if (typeof selector != "undefined" && elem.matches(selector)) break;
  		siblings.push(elem);
  		elem = elem.nextElementSibling;
  	}
  	return siblings;
  }

  prevUntil(elem, selector, includeCurrent) {
    var siblings = [];
    if((typeof includeCurrent !== "boolean" || includeCurrent === false) && typeof elem != "undefined") {
      elem = elem.previousElementSibling;
    }
    while (elem) {
      if (typeof selector != "undefined" && elem.matches(selector)) break;
      siblings.push(elem);
      elem = elem.previousElementSibling;
    }
    return siblings;
  }

  calcPositionLeftWithScale(imageContainer) {
    return ((imageContainer.clientWidth * (1 - this.config.transformation.scaleNotActive)) / 2);
  }

  calcTransformY(isActive, imageContainer, isFullScreen, maxHeight, captionHeight) {
    let y = 0;
    if(isFullScreen) {
      y = (((maxHeight + captionHeight) - imageContainer.clientHeight) / 2) + 90; // +90px fullscreen gallery header
    } else {
      if(!isActive) {
        y = -((captionHeight * 0.2) / 2);
      }
    }
    return y + "px";
  }

  updateProgressNr(active) {
    let activeNum = [...active.parentNode.children].indexOf(active);
    const progress = this.gallery.getElementsByClassName("vrtx-image-gallery-progress-nr")[0];
    progress.textContent = activeNum;
  }

  toHtml(html) {
    const newDiv = document.createElement("div");
    newDiv.insertAdjacentHTML("beforeend", html);
    return newDiv.textContent;
  }

  // Preload first 3 images, active and 1 from each side
  loadImages() {
    const startTime = new Date();
    const numberOfImages = Math.min(3, this.images.length);
    let numberOfImagesLoaded = 0;
    this.images.forEach((image, i) => {
      if(i === 0 || i === 1 || i === (this.images.length - 1)) {
        const img = new Image();
        img.src = image.src;
        img.onload = function() {
          numberOfImagesLoaded++;
        };
      }
    });
    const waitForLoadedImages = setInterval(() => {
      if(numberOfImagesLoaded === numberOfImages && ((new Date() - startTime) >= 50)) { // Wait at least 50ms
        clearInterval(waitForLoadedImages);
        this.gallery.getElementsByClassName("vrtx-image-gallery-loading-bg")[0].remove();
      }
    }, 5);
  }

  // Load visible images in full size if visible in gallery viewport (replaces ?alt=thumbnail)
  // Uses:
  // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
  addAllFullImagesAfterLoad() {
    let options = {
      root: this.gallery,
      rootMargin: '0px',
      threshold: 0.01
    }
    let callback = (entries, observer) => {
      entries.forEach((entry) => {
        const img = entry.target;
        if(entry.isIntersecting) {
          let src = img.getAttribute("src");
          if(src.indexOf("?alt=thumbnail") !== -1 ||
             src.indexOf("/vrtx/dist/resources/uio2/css/images/gallery/empty.png") !== -1) {
            src = img.getAttribute("data-src");
            entry.target.setAttribute("src", src);
          }
        }
      });
    };
    let observer = new IntersectionObserver(callback, options);
    this.gallery.querySelectorAll('.vrtx-image-gallery-image img').forEach(img => {
       observer.observe(img);
    });
  }

  refreshGallerySize() {
    this.turnOffAnimation();

    this.updateDimensions();
    this.transformation();

    this.turnOnAnimation();
  }

  calculateDimensions(image, captionDesc, captionHeight) {
    let w = image.width;
    let h = image.height;
    const isFullScreen = this.gallery.parentElement.classList.contains("vrtx-image-gallery-selected-fullscreen");
    
    let maxW = 0;
    let maxH = 0;

    if(isFullScreen) {
      maxW = document.documentElement.clientWidth * this.config.width.fullScreen.maxOfTotalWidth;
      maxH = document.documentElement.clientHeight * this.config.height.fullScreen.maxOfTotalHeight;
    } else {
      if(window.innerWidth <= this.config.mobileBreakpoint) {
        maxW = document.documentElement.clientWidth - this.config.width.mobile.fullwidthMinusMargin;
        maxH = (document.documentElement.clientWidth - this.config.width.mobile.fullwidthMinusMargin);
      } else {
        maxW = this.config.width.desktop.max;
        maxH = this.config.height.desktop.maxWithoutCaption;
      }
    }

     // Fix strange bug causing image to be little less height then when no caption
    if(isFullScreen && captionHeight > 0) {
      maxH += 30;
    }

    let gcdVal = 1;
    if(w > 0 && h > 0) {
      gcdVal = this.gcd(w, h);
    } else {
      // console.error() or console.warn instead?
      console.log("There is a image without height/width, please edit/save or reupload it:");
      console.log(image);
    }
    const aspectRatio = (w/gcdVal) / (h/gcdVal);
    let newDim = [0,0];

    if(w > maxW || h > maxH) {
      if(h > maxH) {
        newDim = [Math.round(maxH * aspectRatio), maxH];
        if(newDim[0] > maxW) {
          newDim = [maxW, Math.round(maxW / aspectRatio)];
        }
      } else {
        newDim = [maxW, Math.round(maxW / aspectRatio)];
        if(newDim[1] > maxH) {
          newDim = [Math.round(maxH * aspectRatio), maxH];
        }
      }
      w = newDim[0];
      h = newDim[1];
    }
    if(w < this.config.width.desktop.minWithoutCaption && captionDesc != "") {
      w = this.config.width.desktop.minWithoutCaption;
    }

    if(!isNaN(h) && h > 0) {
      this.config.height.mobile.max = Math.max(this.config.height.mobile.max, h);
    }

    return {w: w, h: h};
  }

  updateDimensions() {
    const isFullScreen = this.gallery.parentElement.classList.contains("vrtx-image-gallery-selected-fullscreen");

    this.config.height.mobile.max = 0;

    this.images.forEach((image, i) => {
      const containerElm = this.gallery.getElementsByClassName("vrtx-image-gallery-image-num-" + (i+1))[0];
      let captionHeight = 0;

      if(isFullScreen) {
        const captionElm = containerElm.getElementsByClassName("vrtx-image-gallery-image-description")[0];
        captionHeight = this.getOuterHeight(captionElm);
      }

      const dimensions = this.calculateDimensions(image, image.desc, captionHeight);
      const w = dimensions.w;
      const h = dimensions.h;

      containerElm.style.width = w + "px";
    });
    if(this.config.height.mobile.max <= 150) {
      this.config.height.mobile.max = 150;
    }
  }

  gcd(a, b) {
    return (b === 0) ? a : this.gcd (b, a%b);
  }

  isActiveOrPrevNextToActiveImg(i) {
    const isActiveImg = i === 0;
    const isPrevToActiveImg = i === this.images.length-1;
    const isNextToActiveImg = i === 1;
    return isActiveImg || isPrevToActiveImg || isNextToActiveImg;
  }

  // Credits: https://stackoverflow.com/questions/10787782/full-height-of-a-html-element-div-including-border-padding-and-margin
  getOuterHeight(elm) {
    elm = (typeof elm === 'string') ? document.querySelector(elm) : elm;

    const styles = window.getComputedStyle(elm);
    const margin = parseFloat(styles['marginTop']) +
                   parseFloat(styles['marginBottom']);

    return elm.offsetHeight + margin;
  }

  wrapElement(elm) {
    const wrapper = document.createElement('div');
    elm.parentNode.insertBefore(wrapper, elm);
    wrapper.appendChild(elm);
  }

  trapFullscreenFocus(elm) {
    const firstFocusableEl = elm.querySelector("a.vrtx-image-gallery-toggle-thumbnail-view");

    document.addEventListener("keydown", function (e) {
      if(!elm.classList.contains("vrtx-image-gallery-selected-fullscreen")) return;
      if(!(e.key === 'Tab')) return;

      let lastFocusableEl = elm.querySelector("a.next");
      if(lastFocusableEl.offsetParent === null) {
        lastFocusableEl = elm.querySelectorAll("ul.vrtx-image-gallery-include-thumbs.active-thumbs-in-scroll a");
        if(!lastFocusableEl.length) {
          lastFocusableEl = elm.querySelectorAll("ul.vrtx-image-gallery-include-thumbs a");
        }
        lastFocusableEl = lastFocusableEl[lastFocusableEl.length - 1];
      }

      if(e.shiftKey) {
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } else {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          e.preventDefault();
        }
      }
    });
  }

  turnOffAnimation() {
    this.gallery.classList.add("animation-off");
  }
  turnOnAnimation() {
    setTimeout(() => {
      this.gallery.classList.remove("animation-off");
    }, 10)
  }
}
export default Gallery;
